// src/pages/index.js
import React from 'react';
import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import Introduction from '../components/introduction/Introduction';
import Gallery from '../components/common/Gallery';
import HomeQuery from '../components/common/homequery';
import Review from '../components/common/review';
import { Helmet } from "react-helmet";

const IndexPage = () => (
  <Layout>
    <Header />
    <Helmet>
      <meta charSet="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
      <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
      <meta name="description" content="We pick up your laundry and return it clean, folded, and sorted—all on the same day!" />
      <title>LaundryToGo - Same Day Drop Off</title>
      {/* The Google Maps API is loaded via gatsby-ssr.js */}
    </Helmet>
    <div id="wrapper">
      <Introduction />
      <Gallery />
      <Review />
      <HomeQuery />
    </div>
    <Footer bg={"blueFooter"} />
  </Layout>
);

export default IndexPage;